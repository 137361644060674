import React, { useState } from "react"
import axios from "axios"
import {
  Grid,
  Button,
  Typography,
  Container,
  TextField,
  CircularProgress,
  makeStyles,
  Paper,
} from "@material-ui/core"
import Hero from "../components/hero"

const useStyles = makeStyles(theme => ({
  submissionText: {
    fontSize: "1.2rem",
    fontFamily: "Nunito",
    padding: "50px",
    textAlign: "center",
  },
  bottom: {
    paddingBottom: "20px",
  },
}))
function ContactForm() {
  const classes = useStyles()
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [text, setText] = useState("")
  const [formState, setFormState] = useState("initial")

  const LinkCheck = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
  let responseObj = null

  function handleSubmit() {
    setFormState("loading")
    axios
      .post(
        "https://gi9tdplnub.execute-api.us-east-1.amazonaws.com/default/contact",
        {
          name: name,
          email: email,
          text: text,
          key: "contact",
        }
      )
      .then(
        response => {
          responseObj = response.data
          if (responseObj.status == "success") {
            setFormState("done")
          } else {
            setFormState("error")
          }
        },
        error => {
          setFormState("error")
        }
      )
  }

  if (formState == "loading") {
    return (
      <Grid container spacing={0} alignItems="center" justify="center">
        <br />
        <CircularProgress size={150} thickness={1.5} />
        <br />
      </Grid>
    )
  } else if (formState == "error") {
    if (responseObj) {
      return (
        <div>
          <hr />
          <Typography className={classes.submissionText}>
            Submission Error.{responseObj.info}
          </Typography>
        </div>
      )
    } else {
      return (
        <div>
          <hr />
          <Typography className={classes.submissionText}>
            Submission Error. You may have made too many requests. Please try
            again later.
          </Typography>
        </div>
      )
    }
  } else if (formState == "done") {
    return (
      <Grid container spacing={0} alignItems="center" justify="center">
        <br />
        <Typography className={classes.submissionText}>
          Thank you for your message! Your email has been sent to the team.
        </Typography>
        <br />
      </Grid>
    )
  } else {
    return (
      <Container>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="pamper-name"
          label="Your Name"
          name="pamper-name"
          error={LinkCheck.test(name)}
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <br />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Your email address"
          name="email"
          error={LinkCheck.test(email)}
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <br />
        <TextField
          variant="outlined"
          required
          fullWidth
          id="general-text"
          label="Message"
          placeholder="Your message here..."
          multiline
          rows={8}
          margin="normal"
          error={LinkCheck.test(text)}
          value={text}
          onChange={e => setText(e.target.value)}
        />
        <br />
        <div className={classes.bottom}>
          {name && email && text ? (
            <Button
              className={classes.submit}
              variant="contained"
              style={{
                backgroundColor: "#0072ab",
                color: "white",
                borderRadius: "50px",
              }}
              onClick={() => handleSubmit()}
            >
              Submit Form
            </Button>
          ) : (
            <Button
              className={classes.submit}
              variant="contained"
              style={{
                borderRadius: "50px",
              }}
              disabled
            >
              Enter missing details to submit
            </Button>
          )}
        </div>
      </Container>
    )
  }
}

export default function Contact() {
  const classes = useStyles()

  return (
    <>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={10}>
          <Grid container justify="center" alignItems="center">
            <Grid item sm={7}>
              <Hero
                title="Contact Us"
                bodyText="Get in touch with any queries using the form below."
              />
              <ContactForm />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
